import { permissionGuard } from "@/auth/permissionAuthGuard";
import ApiComponent from "@/components/development/ApiComponent.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { NavigationCategoryEnum } from "@/store/enum/navigationCategoryEnum";
import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import Error from "@/views/Error.vue";
import NoRoles from "@/views/NoRoles.vue";
import OperationView from "@/views/OperationView.vue";
import PartnerFaqDetail from "@/views/PartnerFaqDetail.vue";
import PartnerFaqList from "@/views/PartnerFaqList.vue";
import ActivityLogTable from "@/views/portal/ActivityLogTable.vue";
import PartnerDetailView from "@/views/portal/PartnerDetailView.vue";
import PartnerMessages from "@/views/portal/PartnerMessages.vue";
import PartnerMessagesDetailView from "@/views/portal/PartnerMessagesDetailView.vue";
import PartnerTable from "@/views/portal/PartnerTable.vue";
import PartnerPermissions from "@/views/portal/PartnerUnauthorized.vue";
import PartnerZip from "@/views/portal/PartnerZip.vue";
import Partners from "@/views/portal/Partners.vue";
import RatingView from "@/views/rating/RatingView.vue";
import PermissionTable from "@/views/portal/PermissionTable.vue";
import UserAdministration from "@/views/portal/UserAdministration.vue";
import UserAdministrationDetailView from "@/views/portal/UserAdministrationDetailView.vue";
import UserTable from "@/views/portal/UserTable.vue";
import DigitalSignatureHomeView from "@/views/sign/DigitalSignatureHomeView.vue";
import DocumentTableView from "@/views/sign/DocumentTableView.vue";
import SignRequestDetail from "@/views/sign/SignRequestDetail.vue";
import SignRequestTableView from "@/views/sign/SignRequestTableView.vue";
import ThgEfahrer from "@/views/thg/ThgEfahrer.vue";
import ThgImprint from "@/views/thg/ThgImprint.vue";
import ThgPrivacy from "@/views/thg/ThgPrivacy.vue";
import ThgTerms from "@/views/thg/ThgTerms.vue";
import Affiliate from "@/views/thgPortal/Affiliate.vue";
import AffiliateDetailView from "@/views/thgPortal/AffiliateDetailView.vue";
import TemplateDetailView from "@/views/thgPortal/TemplateDetailView.vue";
import TemplateListView from "@/views/thgPortal/TemplateListView.vue";
import ThgAdvertisementCreate from "@/views/thgPortal/ThgAdvertisementCreate.vue";
import ThgAdvertisementDetail from "@/views/thgPortal/ThgAdvertisementDetail.vue";
import ThgAdvertisment from "@/views/thgPortal/ThgAdvertisment.vue";
import ThgAnalytics from "@/views/thgPortal/ThgAnalytics.vue";
import ThgBatch from "@/views/thgPortal/ThgBatch.vue";
import ThgBatchDetailView from "@/views/thgPortal/ThgBatchDetailView.vue";
import ThgBillingAccountingSelectionView from "@/views/thgPortal/ThgBillingAccountingSelectionView.vue";
import ThgBillingBatchSelectionItemView from "@/views/thgPortal/ThgBillingBatchSelectionItemView.vue";
import ThgBillingBatchView from "@/views/thgPortal/ThgBillingBatchView.vue";
import ThgBillingBccountingSelectionView from "@/views/thgPortal/ThgBillingBccountingSelectionView.vue";
import ThgBillingCreditorSelectionView from "@/views/thgPortal/ThgBillingCreditorSelectionView.vue";
import ThgBillingListView from "@/views/thgPortal/ThgBillingListView.vue";
import ThgBillingNumberDetailView from "@/views/thgPortal/ThgBillingNumberDetailView.vue";
import ThgBillingNumberPartnerDetailView from "@/views/thgPortal/ThgBillingNumberPartnerDetailView.vue";
import ThgBillingPartnerListView from "@/views/thgPortal/ThgBillingPartnerListView.vue";
import ThgBillingSelectionItemView from "@/views/thgPortal/ThgBillingSelectionItemView.vue";
import ThgBillingView from "@/views/thgPortal/ThgBillingView.vue";
import ThgChargingStation from "@/views/thgPortal/ThgChargingStation.vue";
import ThgChargingStationCreateOverviewView from "@/views/thgPortal/ThgChargingStationCreateOverviewView.vue";
import ThgChargingStationCreateView from "@/views/thgPortal/ThgChargingStationCreateView.vue";
import ThgChargingStationDetailView from "@/views/thgPortal/ThgChargingStationDetailView.vue";
import ThgChargingStationUpdateView from "@/views/thgPortal/ThgChargingStationUpdateView.vue";
import ThgDashboard from "@/views/thgPortal/ThgDashboard.vue";
import ThgDuplicateView from "@/views/thgPortal/ThgDuplicateView.vue";
import ThgInsuranceListView from "@/views/thgPortal/ThgInsuranceListView.vue";
import ThgListAdminView from "@/views/thgPortal/ThgListAdminView.vue";
import ThgLogin from "@/views/thgPortal/ThgLogin.vue";
import ThgMeterReading from "@/views/thgPortal/ThgMeterReading.vue";
import ThgMeterReadingDetailView from "@/views/thgPortal/ThgMeterReadingDetailView.vue";
import ThgMeterReadingTable from "@/views/thgPortal/ThgMeterReadingTable.vue";
import ThgMeterReadingUpdateView from "@/views/thgPortal/ThgMeterReadingUpdateView.vue";
import ThgOperations from "@/views/thgPortal/ThgOperations.vue";
import ThgPartnerChargingDetails from "@/views/thgPortal/ThgPartnerChargingDetails.vue";
import ThgPartnerCustomers from "@/views/thgPortal/ThgPartnerCustomers.vue";
import ThgPartnerVehicles from "@/views/thgPortal/ThgPartnerVehicles.vue";
import ThgPortalErrorPage from "@/views/thgPortal/ThgPortalErrorPage.vue";
import ThgPriceTableView from "@/views/thgPortal/ThgPriceTableView.vue";
import ThgQuote from "@/views/thgPortal/ThgQuote.vue";
import ThgQuoteCreateView from "@/views/thgPortal/ThgQuoteCreateView.vue";
import ThgQuoteDetailView from "@/views/thgPortal/ThgQuoteDetailView.vue";
import ThgTable from "@/views/thgPortal/ThgTable.vue";
import ThgWorkitemProcessingView from "@/views/thgPortal/ThgWorkitemProcessingView.vue";
import ChurnView from "@/views/thgPortal/ChurnView.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { getLoginRoutes } from "./login.router";
import { routes as profileRoutes } from "./profile.router";
import { SettingRoutes } from "./settings.router";
const DocumentDetail = () => import("@/views/sign/DocumentDetail.vue");

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [];

profileRoutes.forEach(route => {
  routes.push(route);
});

const partnerRoutes: Array<ICustomRouteConfig> = [
  {
    path: "/thg",
    name: "ThgAdmin",
    component: ThgListAdminView
  },

  {
    path: "/thg/list",
    name: "ThgListAdmin",
    component: ThgListAdminView
  },
  {
    path: "/thg/table",
    name: "ThgTable",
    component: ThgTable
  },
  {
    path: "/thg-meter-reading/table",
    name: "ThgMeterReadingTable",
    component: ThgMeterReadingTable
  },

  {
    path: "/analytics",
    name: "ThgAnalytics",
    component: ThgAnalytics
  },
  {
    path: "/work",
    name: "ThgWorkitemProcessing",
    component: ThgWorkitemProcessingView
  },
  {
    path: "/churn",
    name: "Churn",
    component: ChurnView
  },
  {
    path: "/batch",
    name: "ThgBatch",
    component: ThgBatch
  },
  {
    path: "/batch/:batchId",
    name: "ThgBatchDetail",
    component: ThgBatchDetailView
  },
  {
    path: "/operation",
    name: "ThgOperations",
    component: ThgOperations
  },
  {
    path: "/user",
    name: "UserAdministration",
    component: UserAdministration
  },
  {
    path: "/user/table",
    name: "UserTable",
    component: UserTable
  },
  {
    path: "/user/:userId",
    name: "UserAdministrationDetail",
    component: UserAdministrationDetailView
  },
  {
    path: "/user/:userId/permission",
    name: "PermissionTable",
    component: PermissionTable
  },
  {
    path: "/partner",
    name: "Partner",
    component: Partners
  },
  {
    path: "/partner/table",
    name: "PartnerTable",
    component: PartnerTable
  },
  {
    path: "/partners",
    name: "Partners",
    component: Partners
  },
  {
    path: "/partners/list",
    name: "PartnersList",
    component: Partners
  },
  {
    path: "/partners/table",
    name: "PartnersTable",
    component: PartnerTable
  },
  {
    path: "/partners/:partnerId/billing",
    name: "ThgBillingPartnerListView",
    component: ThgBillingPartnerListView
  },
  {
    path: "/partners/:partnerId/billing/:billingNumber",
    name: "ThgBillingNumberPartnerDetailView",
    component: ThgBillingNumberPartnerDetailView
  },
  {
    path: "/partners/:partnerId/home",
    name: "ThgDashboardPartnerSelected",
    component: ThgDashboard
  },

  {
    path: "/partners/:partnerId/electric-vehicle",
    name: "ThgPartnerVehicles",
    component: ThgPartnerVehicles
  },
  {
    path: "/partners/:partnerId/charging-detail",
    name: "ThgPartnerChargingDetails",
    component: ThgPartnerChargingDetails
  },
  {
    path: "/partners/:partnerId/customer",
    name: "ThgPartnerCustomers",
    component: ThgPartnerCustomers
  },

  {
    path: "/partners/:partnerId/zip",
    name: "PartnerZip",
    component: PartnerZip
  },
  {
    path: "/partners/:partnerId/message",
    name: "PartnerMessages",
    component: PartnerMessages
  },
  {
    path: "/partners/:partnerId/message/:messageId",
    name: "PartnerMessagesDetailView",
    component: PartnerMessagesDetailView
  },
  {
    path: "/partners/:partnerId/thg",
    name: "ThgQuote",
    component: ThgQuote
  },
  {
    path: "/partners/:partnerId/thg/new",
    name: "ThgQuoteCreateView",
    component: ThgQuoteCreateView
  },
  {
    path: "/partners/:partnerId/thg/:thgId",
    name: "ThgQuoteDetailView",
    component: ThgQuoteDetailView
  },
  {
    path: "/partners/:partnerId/affiliate",
    name: "Affiliate",
    component: Affiliate
  },
  {
    path: "/partners/:partnerId/affiliate/:affiliateId",
    name: "AffiliateDetailView",
    component: AffiliateDetailView
  },
  {
    path: "/partners/:partnerId/faq/:faqId/",
    name: "PartnerFaqDetail",
    component: PartnerFaqDetail
  },
  {
    path: "/partners/:partnerId/faq/",
    name: "PartnerFaqList",
    component: PartnerFaqList
  },
  {
    path: "/partners/:partnerId/info",
    name: "PartnerDetailViewInfo",
    component: PartnerDetailView
  },
  {
    path: "/partners/:partnerId/landing",
    name: "PartnerDetailViewLanding",
    component: PartnerDetailView
  },
  {
    path: "/partners/:partnerId/damage",
    name: "PartnerDetailViewDamage",
    component: PartnerDetailView
  },
  {
    path: "/partners/:partnerId/license",
    name: "PartnerDetailViewLicense",
    component: PartnerDetailView
  },
  {
    path: "/partners/:partnerId/banking",
    name: "PartnerDetailViewBilling",
    component: PartnerDetailView
  },
  {
    path: "/partners/:partnerId/template",
    name: "TemplateListPartnerView",
    component: TemplateListView
  },
  {
    path: "/partners/:partnerId/template/:key",
    name: "TemplateDetailPartnerView",
    component: TemplateDetailView
  },
  {
    path: "/partners/:partnerId/document",
    name: "DigitalSignatureHomeView",
    component: DigitalSignatureHomeView,
    permission: {
      resource: ResourceEnum.DOCUMENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SIGN,
      icon: "mdi-file-document-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    // Tab can be ResourceEnum.SIGN or ResourceEnum.DOCUMENT
    path: "/partners/:partnerId/document/tab/:tab",
    name: "DigitalSignatureHomeViewTab",
    component: DigitalSignatureHomeView,
    permission: {
      resource: ResourceEnum.DOCUMENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/partners/:partnerId/document/list",
    name: "DocumentTableView",
    component: DocumentTableView,
    permission: {
      resource: ResourceEnum.DOCUMENT,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SIGN,
      icon: "mdi-file-document-multiple-outline",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/partners/:partnerId/document/:documentId",
    name: "DocumentDetailView",
    component: DocumentDetail
  },
  {
    path: "/partners/:partnerId/sign-request",
    name: "SignRequestTable",
    component: SignRequestTableView
  },
  {
    path: "/partners/:partnerId/sign-request/:signRequestId",
    name: "SignRequestDetailView",
    component: SignRequestDetail
  },
  // Replicating for compatibility
  {
    path: "/partner/:partnerId/sign-request",
    name: "PartnerSignRequestTable",
    component: SignRequestTableView
  },
  {
    path: "/partner/:partnerId/sign-request/:signRequestId",
    name: "PartnerSignRequestDetailView",
    component: SignRequestDetail
  },
  {
    path: "/partners/:partnerId",
    name: "PartnerDetailView",
    component: PartnerDetailView
  },
  {
    path: "/charging-station",
    name: "ThgChargingStation",
    component: ThgChargingStation
  },
  {
    path: "/charging-station/new",
    name: "ThgChargingStationCreateOverviewView",
    component: ThgChargingStationCreateOverviewView
  },
  {
    path: "/charging-station/new/single",
    name: "ThgChargingStationCreateView",
    component: ThgChargingStationCreateView
  },
  {
    path: "/charging-station/:chargingStationId",
    name: "ThgChargingStationDetailView",
    component: ThgChargingStationDetailView
  },
  {
    path: "/charging-station/:chargingStationId/edit",
    name: "ThgChargingStationUpdateView",
    component: ThgChargingStationUpdateView
  },
  {
    path: "/meter-reading",
    name: "ThgMeterReading",
    component: ThgMeterReading
  },
  {
    path: "/meter-reading/:meterReadingId",
    name: "ThgMeterReadingDetailView",
    component: ThgMeterReadingDetailView
  },
  {
    path: "/meter-reading/:meterReadingId/edit",
    name: "ThgMeterReadingUpdateView",
    component: ThgMeterReadingUpdateView
  },
  {
    path: "/insurance",
    name: "ThgInsuranceListView",
    component: ThgInsuranceListView
  },
  {
    path: "/price",
    name: "ThgPriceTable",
    component: ThgPriceTableView,
    permission: {
      resource: ResourceEnum.PRICE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/price/:tab",
    name: "ThgPriceTab",
    component: ThgPriceTableView,
    permission: {
      resource: ResourceEnum.PRICE,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    }
  },
  {
    path: "/rating",
    name: "RatingView",
    component: RatingView
  },
  {
    path: "/error",
    name: "PartnerError",
    component: ThgPortalErrorPage
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: PartnerPermissions
  },

  {
    path: "/no-roles",
    name: "NoRoles",
    component: NoRoles
  },
  {
    path: "/errors",
    name: "Error",
    component: Error
  },

  {
    path: "/dup",
    name: "ThgDuplicateView",
    component: ThgDuplicateView
  },
  {
    path: "/operation/:operationId",
    name: "OperationView",
    component: OperationView
  },
  {
    path: "/operation/:operationId",
    name: "OperationPartnerView",
    component: OperationView
  },
  {
    path: "/billing/creation",
    name: "ThgBillingSelection",
    component: ThgBillingView
  },
  {
    path: "/billing/creation/:billingType",
    name: "ThgBillingSelectionType",
    component: ThgBillingView
  },
  {
    path: "/billing/creation/:billingType/:i",
    name: "ThgBillingSelectionItemView",
    component: ThgBillingSelectionItemView
  },
  {
    path: "/billing-batch/creation",
    name: "ThgBillingBatchSelection",
    component: ThgBillingBatchView
  },
  {
    path: "/billing-batch/creation/:billingType",
    name: "ThgBillingBatchSelectionType",
    component: ThgBillingBatchView
  },
  {
    path: "/billing-batch/creation/:billingType/item",
    name: "ThgBillingBatchSelectionItemView",
    component: ThgBillingBatchSelectionItemView
  },
  {
    path: "/billing",
    name: "ThgBillingView",
    component: ThgBillingListView
  },
  {
    path: "/billing/list",
    name: "ThgBillingListView",
    component: ThgBillingListView
  },
  {
    path: "/billing/accounting",
    name: "ThgBillingAccountingSelectionView",
    component: ThgBillingAccountingSelectionView
  },
  {
    path: "/billing/bccounting",
    name: "ThgBillingBccountingSelectionView",
    component: ThgBillingBccountingSelectionView
  },
  {
    path: "/billing/creditor",
    name: "ThgBillingCreditorSelectionView",
    component: ThgBillingCreditorSelectionView
  },
  {
    path: "/billing/:billingNumber",
    name: "ThgBillingNumberDetailView",
    component: ThgBillingNumberDetailView
  },
  {
    path: "/template",
    name: "TemplateListView",
    component: TemplateListView
  },
  {
    path: "/template/:key",
    name: "TemplateDetailView",
    component: TemplateDetailView
  },
  {
    path: "/advertisement",
    name: "ThgAdvertisement",
    component: ThgAdvertisment
  },
  {
    path: "/advertisement/new",
    name: "ThgAdvertisementCreate",
    component: ThgAdvertisementCreate
  },
  {
    path: "partner/:partnerId/advertisement/:advertisementId",
    name: "ThgAdvertisementDetail",
    component: ThgAdvertisementDetail
  },
  {
    path: "/partners/:partnerId/activity-log",
    name: "ActivityLogTable",
    component: ActivityLogTable,
    //TODO: Discuss permission
    permission: {
      resource: ResourceEnum.PARTNER,
      action: ActionEnum.READ,
      field: ResourceEnum.PARTNER
    },
    navigation: {
      category: NavigationCategoryEnum.SETTINGS,
      icon: "mdi-cog-clockwise",
      isEnabled: true,
      isSideBarNavigation: false,
      isQuickLink: true,
      isProfileMenu: false
    }
  },
  {
    path: "/efahrer",
    name: "ThgEfahrer",
    component: ThgEfahrer,
    permission: {
      resource: ResourceEnum.ALL,
      action: ActionEnum.MANAGE
    }
  },
  {
    path: "/agbs",
    name: "AGBs",
    component: ThgTerms
  },
  {
    path: "/privacy",
    name: "Datenschutz",
    component: ThgPrivacy
  },
  {
    path: "/imprint",
    name: "Impressum",
    component: ThgImprint
  },
  {
    path: "*",
    name: "ThgDashboard",
    component: ThgDashboard
  }
];

partnerRoutes.forEach(route => {
  routes.push(route);
});

getLoginRoutes(ThgLogin).forEach((route: RouteConfig) => {
  route.path = "/login" + route.path;
  routes.push(route);
});

SettingRoutes.forEach((route: RouteConfig) => {
  route.path = "/settings" + route.path;
  routes.push(route);
});

routes.push({
  path: "/api",
  name: "api",
  component: ApiComponent
});

const router = new VueRouter({
  routes,
  linkExactActiveClass: "router-link-exact-path-active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(permissionGuard);

export default router;
